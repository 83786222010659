import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FunctionComponent } from 'react';
import ThemeProvider from './ThemeProvider';

const ComponentsProvider: FunctionComponent<{
    children: JSX.Element;
}> = ({ children }) => (
    <ThemeProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            {children}
        </MuiPickersUtilsProvider>
    </ThemeProvider>
);

export default ComponentsProvider;
