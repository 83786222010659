import { IconButton, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';

export interface DateFieldProps {
    label: string;
    value: Moment|null;
    onChange: (newValue: Moment|null) => void;
    className?: string;
    required?: boolean;
    helperText?: string;
    utc?: boolean;
    error?: boolean;
    format: string;
    minimum?: Moment | null;
}

const DateField: FunctionComponent<DateFieldProps> = ({
    label, value, onChange, className, required,
    helperText, utc, error, format, minimum,
}) => {
    const [hovering, setHovering] = useState(false);
    const [focusing, setFocusing] = useState(false);

    const filteredValue = value === null || minimum && value.isBefore(minimum, 'day')
        ? null
        : value;

    return (
        <DatePicker
            value={filteredValue}
            onChange={newValue => {
                const result = (() => {
                    if (newValue === null) {
                        return null;
                    }

                    return utc
                        ? moment.utc(
                            newValue.format('YYYY-MM-DDTHH:mm:ss'),
                            'YYYY-MM-DDTHH:mm:ss',
                        )
                        : newValue;
                })();

                onChange(result);
            }}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onFocus={() => setFocusing(true)}
            onBlur={() => setFocusing(false)}
            label={utc ? `${label} (UTC)` : label}
            className={className}
            required={required}
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/no-unnecessary-condition
            helperText={helperText || ' '}
            inputVariant="filled"
            autoOk
            variant="inline"
            InputProps={{
                endAdornment: (hovering || focusing) && filteredValue !== null
                    ? (
                        <InputAdornment position="end">
                            <IconButton

                                // TODO: Translate
                                aria-label="Clear"
                                onClick={event => {
                                    event.stopPropagation();
                                    onChange(null);
                                }}
                                onMouseDown={event => event.preventDefault()}
                            >
                                <ClearIcon/>
                            </IconButton>
                        </InputAdornment>
                    )
                    : null,
            }}
            fullWidth
            error={error}
            format={format}
            minDate={minimum}
        />
    );
};

export default DateField;
